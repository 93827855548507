import React from "react";
import MuiButton, {
  ButtonProps as MuiButtonProps,
} from "@material-ui/core/Button";
import styled, { DefaultTheme } from "styled-components";

/**
 * Returns the color string for CSS background-color property
 *
 * @param {DefaultTheme} theme
 * @param {ButtonVariant} [variant="solid"]
 * @param {boolean} [isDisabled=false]
 * @param {boolean} [isNeutral=false]
 * @returns {string}
 */
function renderBgColor(
  theme: DefaultTheme,
  variant: ButtonVariant = "solid",
  isDisabled = false,
  isNeutral = false,
): string {
  switch (variant) {
    case "solid":
      if (isDisabled) {
        return "#dddddd";
      }
      if (isNeutral) {
        return "#f3f4f8";
      }
      return theme.palette.primary.main;
    case "line":
    case "text":
      return theme.palette.common.white;
  }
}

/**
 * Returns the color string for CSS background-color property on hover
 *
 * @param {DefaultTheme} theme
 * @param {ButtonVariant} [variant="solid"]
 * @param {boolean} [isNeutral=false]
 * @returns {string}
 */
function renderHoverBgColor(
  theme: DefaultTheme,
  variant: ButtonVariant = "solid",
  isDisabled = false,
  isNeutral = false,
): string {
  switch (variant) {
    case "solid":
      if (isDisabled) {
        return "#dddddd";
      }
      if (isNeutral) {
        return "#dcdeea";
      }
      return "#0050de";
    case "line":
    case "text":
      return theme.palette.blueGrey[100];
  }
}

/**
 * Returns the color string for CSS color property
 *
 * @param {DefaultTheme} theme
 * @param {ButtonVariant} [variant="solid"]
 * @param {boolean} [isDisabled=false]
 * @returns {string}
 */
function renderColor(
  theme: DefaultTheme,
  variant: ButtonVariant = "solid",
  isDisabled = false,
  isNeutral = false,
): string {
  switch (variant) {
    case "solid":
      if (isNeutral) {
        return "#00194D";
      }
      return theme.palette.common.white;
    case "line":
    case "text":
      if (isDisabled) {
        return "#dddddd";
      }
      if (isNeutral) {
        return "#666666";
      }
      return theme.palette.primary.main;
  }
}

/**
 * Returns the border color string for CSS border color property
 *
 * @param {DefaultTheme} theme
 * @param {boolean} [isDisabled=false]
 * @param {boolean} [isNeutral=false]
 * @returns {string}
 */
function renderBorderColor(
  theme: DefaultTheme,
  isDisabled = false,
  isNeutral = false,
  variant: ButtonVariant = "solid",
): string {
  if (isDisabled) {
    return "#dddddd";
  }
  if (isNeutral) {
    if (variant === "line") {
      return "#eeeeee";
    }
    return "#666666";
  }
  return theme.palette.primary.main;
}

/**
 * Returns the padding value for CSS padding property
 *
 * @param {ButtonSize} [size="medium"]
 * @returns {string}
 */
function renderPadding(size: ButtonSize = "medium"): string {
  switch (size) {
    case "large":
    case "medium":
      return "16px 20px";
    case "small":
      return "13px 16px";
    case "xs":
      return "8.5px 16px";
  }
}

/**
 * Returns the font size value for CSS font-size property
 *
 * @param {ButtonSize} [size="medium"]
 * @returns {string}
 */
function renderFontSize(size: ButtonSize = "medium"): string {
  switch (size) {
    case "large":
    case "medium":
      return "16px";
    case "small":
    case "xs":
      return "14px";
  }
}

export type ButtonVariant = "solid" | "line" | "text";

export type ButtonSize = "xs" | "small" | "medium" | "large";

export type ButtonProps = Omit<MuiButtonProps, "variant" | "size"> & {
  /**
   * Width in pixel.
   * Default is 100%
   *
   * @type {number}
   * @memberof ButtonProps
   */
  width?: number | string;
  /**
   * "solid" | "line" | "text"
   *
   * @type {ButtonVariant}
   */
  variant?: ButtonVariant;
  neutral?: boolean;
  size?: ButtonSize;
  round?: boolean;
};

const Button = styled(
  React.forwardRef<any, ButtonProps>(
    ({ variant, size, neutral, round, ...rest }, ref) => (
      <MuiButton
        ref={ref}
        disableRipple
        fullWidth
        classes={{ label: "button-label-root" }}
        {...rest}
      />
    ),
  ),
)`
  // root styling goes here
  &&& {
    width: ${({ width }) =>
      width ? (typeof width === "string" ? width : `${width}px`) : "100%"};
    background-color: ${({ theme, variant, disabled, neutral }) =>
      renderBgColor(theme, variant, disabled, neutral)};
    border-radius: ${({ round }) => (round ? "30px" : "10px")};
    color: ${({ theme, variant, disabled, neutral }) =>
      renderColor(theme, variant, disabled, neutral)};
    padding: ${({ size }) => renderPadding(size)};
    text-transform: none;
    line-height: ${({ size }) => (size === "small" ? 1.57 : 1.5)};
    font-size: ${({ size }) => renderFontSize(size)};
    font-weight: 500;
    border-style: solid;
    border-width: ${({ variant }) => (variant === "line" ? "1px" : "0")};
    border-color: ${({ disabled, theme, neutral, variant }) =>
      renderBorderColor(theme, disabled, neutral, variant)};

    .button-label-root {
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
    }
  }

  &&&:hover {
    background-color: ${({ theme, variant, disabled, neutral }) =>
      renderHoverBgColor(theme, variant, disabled, neutral)};
  }

  &&&:focus {
    background-color: ${({ theme, variant, disabled, neutral }) =>
      renderHoverBgColor(theme, variant, disabled, neutral)};
  }
`;

export default Button;
