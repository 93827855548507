import { uniqBy, sortBy, compose, prop } from "ramda";
import type { SelectOption } from "lib/components/Select";

const dialCodes = [
  {
    name: "Afghanistan",
    label: "+93",
    value: "+93",
    key: "AF",
  },
  {
    name: "Åland Islands",
    label: "+358",
    value: "+358",
    key: "AX",
  },
  {
    name: "Albania",
    label: "+355",
    value: "+355",
    key: "AL",
  },
  {
    name: "Algeria",
    label: "+213",
    value: "+213",
    key: "DZ",
  },
  {
    name: "American Samoa",
    label: "+1684",
    value: "+1684",
    key: "AS",
  },
  {
    name: "Andorra",
    label: "+376",
    value: "+376",
    key: "AD",
  },
  {
    name: "Angola",
    label: "+244",
    value: "+244",
    key: "AO",
  },
  {
    name: "Anguilla",
    label: "+1264",
    value: "+1264",
    key: "AI",
  },
  {
    name: "Antarctica",
    label: "+672",
    value: "+672",
    key: "AQ",
  },
  {
    name: "Antigua and Barbuda",
    label: "+1268",
    value: "+1268",
    key: "AG",
  },
  {
    name: "Argentina",
    label: "+54",
    value: "+54",
    key: "AR",
  },
  {
    name: "Armenia",
    label: "+374",
    value: "+374",
    key: "AM",
  },
  {
    name: "Aruba",
    label: "+297",
    value: "+297",
    key: "AW",
  },
  {
    name: "Australia",
    label: "+61",
    value: "+61",
    key: "AU",
  },
  {
    name: "Austria",
    label: "+43",
    value: "+43",
    key: "AT",
  },
  {
    name: "Azerbaijan",
    label: "+994",
    value: "+994",
    key: "AZ",
  },
  {
    name: "Bahamas",
    label: "+1242",
    value: "+1242",
    key: "BS",
  },
  {
    name: "Bahrain",
    label: "+973",
    value: "+973",
    key: "BH",
  },
  {
    name: "Bangladesh",
    label: "+880",
    value: "+880",
    key: "BD",
  },
  {
    name: "Barbados",
    label: "+1246",
    value: "+1246",
    key: "BB",
  },
  {
    name: "Belarus",
    label: "+375",
    value: "+375",
    key: "BY",
  },
  {
    name: "Belgium",
    label: "+32",
    value: "+32",
    key: "BE",
  },
  {
    name: "Belize",
    label: "+501",
    value: "+501",
    key: "BZ",
  },
  {
    name: "Benin",
    label: "+229",
    value: "+229",
    key: "BJ",
  },
  {
    name: "Bermuda",
    label: "+1441",
    value: "+1441",
    key: "BM",
  },
  {
    name: "Bhutan",
    label: "+975",
    value: "+975",
    key: "BT",
  },
  {
    name: "Bolivia, Plurinational State of bolivia",
    label: "+591",
    value: "+591",
    key: "BO",
  },
  {
    name: "Bosnia and Herzegovina",
    label: "+387",
    value: "+387",
    key: "BA",
  },
  {
    name: "Botswana",
    label: "+267",
    value: "+267",
    key: "BW",
  },
  {
    name: "Bouvet Island",
    label: "+47",
    value: "+47",
    key: "BV",
  },
  {
    name: "Brazil",
    label: "+55",
    value: "+55",
    key: "BR",
  },
  {
    name: "British Indian Ocean Territory",
    label: "+246",
    value: "+246",
    key: "IO",
  },
  {
    name: "Brunei Darussalam",
    label: "+673",
    value: "+673",
    key: "BN",
  },
  {
    name: "Bulgaria",
    label: "+359",
    value: "+359",
    key: "BG",
  },
  {
    name: "Burkina Faso",
    label: "+226",
    value: "+226",
    key: "BF",
  },
  {
    name: "Burundi",
    label: "+257",
    value: "+257",
    key: "BI",
  },
  {
    name: "Cambodia",
    label: "+855",
    value: "+855",
    key: "KH",
  },
  {
    name: "Cameroon",
    label: "+237",
    value: "+237",
    key: "CM",
  },
  {
    name: "Canada",
    label: "+1",
    value: "+1",
    key: "CA",
  },
  {
    name: "Cape Verde",
    label: "+238",
    value: "+238",
    key: "CV",
  },
  {
    name: "Cayman Islands",
    label: "+345",
    value: "+345",
    key: "KY",
  },
  {
    name: "Central African Republic",
    label: "+236",
    value: "+236",
    key: "CF",
  },
  {
    name: "Chad",
    label: "+235",
    value: "+235",
    key: "TD",
  },
  {
    name: "Chile",
    label: "+56",
    value: "+56",
    key: "CL",
  },
  {
    name: "China",
    label: "+86",
    value: "+86",
    key: "CN",
  },
  {
    name: "Christmas Island",
    label: "+61",
    value: "+61",
    key: "CX",
  },
  {
    name: "Cocos (Keeling) Islands",
    label: "+61",
    value: "+61",
    key: "CC",
  },
  {
    name: "Colombia",
    label: "+57",
    value: "+57",
    key: "CO",
  },
  {
    name: "Comoros",
    label: "+269",
    value: "+269",
    key: "KM",
  },
  {
    name: "Congo",
    label: "+242",
    value: "+242",
    key: "CG",
  },
  {
    name: "Congo, The Democratic Republic of the Congo",
    label: "+243",
    value: "+243",
    key: "CD",
  },
  {
    name: "Cook Islands",
    label: "+682",
    value: "+682",
    key: "CK",
  },
  {
    name: "Costa Rica",
    label: "+506",
    value: "+506",
    key: "CR",
  },
  {
    name: "Cote d'Ivoire",
    label: "+225",
    value: "+225",
    key: "CI",
  },
  {
    name: "Croatia",
    label: "+385",
    value: "+385",
    key: "HR",
  },
  {
    name: "Cuba",
    label: "+53",
    value: "+53",
    key: "CU",
  },
  {
    name: "Cyprus",
    label: "+357",
    value: "+357",
    key: "CY",
  },
  {
    name: "Czech Republic",
    label: "+420",
    value: "+420",
    key: "CZ",
  },
  {
    name: "Denmark",
    label: "+45",
    value: "+45",
    key: "DK",
  },
  {
    name: "Djibouti",
    label: "+253",
    value: "+253",
    key: "DJ",
  },
  {
    name: "Dominica",
    label: "+1767",
    value: "+1767",
    key: "DM",
  },
  {
    name: "Dominican Republic",
    label: "+1849",
    value: "+1849",
    key: "DO",
  },
  {
    name: "Ecuador",
    label: "+593",
    value: "+593",
    key: "EC",
  },
  {
    name: "Egypt",
    label: "+20",
    value: "+20",
    key: "EG",
  },
  {
    name: "El Salvador",
    label: "+503",
    value: "+503",
    key: "SV",
  },
  {
    name: "Equatorial Guinea",
    label: "+240",
    value: "+240",
    key: "GQ",
  },
  {
    name: "Eritrea",
    label: "+291",
    value: "+291",
    key: "ER",
  },
  {
    name: "Estonia",
    label: "+372",
    value: "+372",
    key: "EE",
  },
  {
    name: "Ethiopia",
    label: "+251",
    value: "+251",
    key: "ET",
  },
  {
    name: "Falkland Islands (Malvinas)",
    label: "+500",
    value: "+500",
    key: "FK",
  },
  {
    name: "Faroe Islands",
    label: "+298",
    value: "+298",
    key: "FO",
  },
  {
    name: "Fiji",
    label: "+679",
    value: "+679",
    key: "FJ",
  },
  {
    name: "Finland",
    label: "+358",
    value: "+358",
    key: "FI",
  },
  {
    name: "France",
    label: "+33",
    value: "+33",
    key: "FR",
  },
  {
    name: "French Guiana",
    label: "+594",
    value: "+594",
    key: "GF",
  },
  {
    name: "French Polynesia",
    label: "+689",
    value: "+689",
    key: "PF",
  },
  {
    name: "French Southern Territories",
    label: "+262",
    value: "+262",
    key: "TF",
  },
  {
    name: "Gabon",
    label: "+241",
    value: "+241",
    key: "GA",
  },
  {
    name: "Gambia",
    label: "+220",
    value: "+220",
    key: "GM",
  },
  {
    name: "Georgia",
    label: "+995",
    value: "+995",
    key: "GE",
  },
  {
    name: "Germany",
    label: "+49",
    value: "+49",
    key: "DE",
  },
  {
    name: "Ghana",
    label: "+233",
    value: "+233",
    key: "GH",
  },
  {
    name: "Gibraltar",
    label: "+350",
    value: "+350",
    key: "GI",
  },
  {
    name: "Greece",
    label: "+30",
    value: "+30",
    key: "GR",
  },
  {
    name: "Greenland",
    label: "+299",
    value: "+299",
    key: "GL",
  },
  {
    name: "Grenada",
    label: "+1473",
    value: "+1473",
    key: "GD",
  },
  {
    name: "Guadeloupe",
    label: "+590",
    value: "+590",
    key: "GP",
  },
  {
    name: "Guam",
    label: "+1671",
    value: "+1671",
    key: "GU",
  },
  {
    name: "Guatemala",
    label: "+502",
    value: "+502",
    key: "GT",
  },
  {
    name: "Guernsey",
    label: "+44",
    value: "+44",
    key: "GG",
  },
  {
    name: "Guinea",
    label: "+224",
    value: "+224",
    key: "GN",
  },
  {
    name: "Guinea-Bissau",
    label: "+245",
    value: "+245",
    key: "GW",
  },
  {
    name: "Guyana",
    label: "+592",
    value: "+592",
    key: "GY",
  },
  {
    name: "Haiti",
    label: "+509",
    value: "+509",
    key: "HT",
  },
  {
    name: "Heard Island and Mcdonald Islands",
    label: "+0",
    value: "+0",
    key: "HM",
  },
  {
    name: "Holy See (Vatican City State)",
    label: "+379",
    value: "+379",
    key: "VA",
  },
  {
    name: "Honduras",
    label: "+504",
    value: "+504",
    key: "HN",
  },
  {
    name: "Hong Kong",
    label: "+852",
    value: "+852",
    key: "HK",
  },
  {
    name: "Hungary",
    label: "+36",
    value: "+36",
    key: "HU",
  },
  {
    name: "Iceland",
    label: "+354",
    value: "+354",
    key: "IS",
  },
  {
    name: "India",
    label: "+91",
    value: "+91",
    key: "IN",
  },
  {
    name: "Indonesia",
    label: "+62",
    value: "+62",
    key: "ID",
  },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    label: "+98",
    value: "+98",
    key: "IR",
  },
  {
    name: "Iraq",
    label: "+964",
    value: "+964",
    key: "IQ",
  },
  {
    name: "Ireland",
    label: "+353",
    value: "+353",
    key: "IE",
  },
  {
    name: "Isle of Man",
    label: "+44",
    value: "+44",
    key: "IM",
  },
  {
    name: "Israel",
    label: "+972",
    value: "+972",
    key: "IL",
  },
  {
    name: "Italy",
    label: "+39",
    value: "+39",
    key: "IT",
  },
  {
    name: "Jamaica",
    label: "+1876",
    value: "+1876",
    key: "JM",
  },
  {
    name: "Japan",
    label: "+81",
    value: "+81",
    key: "JP",
  },
  {
    name: "Jersey",
    label: "+44",
    value: "+44",
    key: "JE",
  },
  {
    name: "Jordan",
    label: "+962",
    value: "+962",
    key: "JO",
  },
  {
    name: "Kazakhstan",
    label: "+7",
    value: "+7",
    key: "KZ",
  },
  {
    name: "Kenya",
    label: "+254",
    value: "+254",
    key: "KE",
  },
  {
    name: "Kiribati",
    label: "+686",
    value: "+686",
    key: "KI",
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    label: "+850",
    value: "+850",
    key: "KP",
  },
  {
    name: "Korea, Republic of South Korea",
    label: "+82",
    value: "+82",
    key: "KR",
  },
  {
    name: "Kosovo",
    label: "+383",
    value: "+383",
    key: "XK",
  },
  {
    name: "Kuwait",
    label: "+965",
    value: "+965",
    key: "KW",
  },
  {
    name: "Kyrgyzstan",
    label: "+996",
    value: "+996",
    key: "KG",
  },
  {
    name: "Laos",
    label: "+856",
    value: "+856",
    key: "LA",
  },
  {
    name: "Latvia",
    label: "+371",
    value: "+371",
    key: "LV",
  },
  {
    name: "Lebanon",
    label: "+961",
    value: "+961",
    key: "LB",
  },
  {
    name: "Lesotho",
    label: "+266",
    value: "+266",
    key: "LS",
  },
  {
    name: "Liberia",
    label: "+231",
    value: "+231",
    key: "LR",
  },
  {
    name: "Libyan Arab Jamahiriya",
    label: "+218",
    value: "+218",
    key: "LY",
  },
  {
    name: "Liechtenstein",
    label: "+423",
    value: "+423",
    key: "LI",
  },
  {
    name: "Lithuania",
    label: "+370",
    value: "+370",
    key: "LT",
  },
  {
    name: "Luxembourg",
    label: "+352",
    value: "+352",
    key: "LU",
  },
  {
    name: "Macao",
    label: "+853",
    value: "+853",
    key: "MO",
  },
  {
    name: "Macedonia",
    label: "+389",
    value: "+389",
    key: "MK",
  },
  {
    name: "Madagascar",
    label: "+261",
    value: "+261",
    key: "MG",
  },
  {
    name: "Malawi",
    label: "+265",
    value: "+265",
    key: "MW",
  },
  {
    name: "Malaysia",
    label: "+60",
    value: "+60",
    key: "MY",
  },
  {
    name: "Maldives",
    label: "+960",
    value: "+960",
    key: "MV",
  },
  {
    name: "Mali",
    label: "+223",
    value: "+223",
    key: "ML",
  },
  {
    name: "Malta",
    label: "+356",
    value: "+356",
    key: "MT",
  },
  {
    name: "Marshall Islands",
    label: "+692",
    value: "+692",
    key: "MH",
  },
  {
    name: "Martinique",
    label: "+596",
    value: "+596",
    key: "MQ",
  },
  {
    name: "Mauritania",
    label: "+222",
    value: "+222",
    key: "MR",
  },
  {
    name: "Mauritius",
    label: "+230",
    value: "+230",
    key: "MU",
  },
  {
    name: "Mayotte",
    label: "+262",
    value: "+262",
    key: "YT",
  },
  {
    name: "Mexico",
    label: "+52",
    value: "+52",
    key: "MX",
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    label: "+691",
    value: "+691",
    key: "FM",
  },
  {
    name: "Moldova",
    label: "+373",
    value: "+373",
    key: "MD",
  },
  {
    name: "Monaco",
    label: "+377",
    value: "+377",
    key: "MC",
  },
  {
    name: "Mongolia",
    label: "+976",
    value: "+976",
    key: "MN",
  },
  {
    name: "Montenegro",
    label: "+382",
    value: "+382",
    key: "ME",
  },
  {
    name: "Montserrat",
    label: "+1664",
    value: "+1664",
    key: "MS",
  },
  {
    name: "Morocco",
    label: "+212",
    value: "+212",
    key: "MA",
  },
  {
    name: "Mozambique",
    label: "+258",
    value: "+258",
    key: "MZ",
  },
  {
    name: "Myanmar",
    label: "+95",
    value: "+95",
    key: "MM",
  },
  {
    name: "Namibia",
    label: "+264",
    value: "+264",
    key: "NA",
  },
  {
    name: "Nauru",
    label: "+674",
    value: "+674",
    key: "NR",
  },
  {
    name: "Nepal",
    label: "+977",
    value: "+977",
    key: "NP",
  },
  {
    name: "Netherlands",
    label: "+31",
    value: "+31",
    key: "NL",
  },
  {
    name: "Netherlands Antilles",
    label: "+599",
    value: "+599",
    key: "AN",
  },
  {
    name: "New Caledonia",
    label: "+687",
    value: "+687",
    key: "NC",
  },
  {
    name: "New Zealand",
    label: "+64",
    value: "+64",
    key: "NZ",
  },
  {
    name: "Nicaragua",
    label: "+505",
    value: "+505",
    key: "NI",
  },
  {
    name: "Niger",
    label: "+227",
    value: "+227",
    key: "NE",
  },
  {
    name: "Nigeria",
    label: "+234",
    value: "+234",
    key: "NG",
  },
  {
    name: "Niue",
    label: "+683",
    value: "+683",
    key: "NU",
  },
  {
    name: "Norfolk Island",
    label: "+672",
    value: "+672",
    key: "NF",
  },
  {
    name: "Northern Mariana Islands",
    label: "+1670",
    value: "+1670",
    key: "MP",
  },
  {
    name: "Norway",
    label: "+47",
    value: "+47",
    key: "NO",
  },
  {
    name: "Oman",
    label: "+968",
    value: "+968",
    key: "OM",
  },
  {
    name: "Pakistan",
    label: "+92",
    value: "+92",
    key: "PK",
  },
  {
    name: "Palau",
    label: "+680",
    value: "+680",
    key: "PW",
  },
  {
    name: "Palestinian Territory, Occupied",
    label: "+970",
    value: "+970",
    key: "PS",
  },
  {
    name: "Panama",
    label: "+507",
    value: "+507",
    key: "PA",
  },
  {
    name: "Papua New Guinea",
    label: "+675",
    value: "+675",
    key: "PG",
  },
  {
    name: "Paraguay",
    label: "+595",
    value: "+595",
    key: "PY",
  },
  {
    name: "Peru",
    label: "+51",
    value: "+51",
    key: "PE",
  },
  {
    name: "The Philippines",
    label: "+63",
    value: "+63",
    key: "PH",
  },
  {
    name: "Pitcairn",
    label: "+64",
    value: "+64",
    key: "PN",
  },
  {
    name: "Poland",
    label: "+48",
    value: "+48",
    key: "PL",
  },
  {
    name: "Portugal",
    label: "+351",
    value: "+351",
    key: "PT",
  },
  {
    name: "Puerto Rico",
    label: "+1939",
    value: "+1939",
    key: "PR",
  },
  {
    name: "Qatar",
    label: "+974",
    value: "+974",
    key: "QA",
  },
  {
    name: "Romania",
    label: "+40",
    value: "+40",
    key: "RO",
  },
  {
    name: "Russia",
    label: "+7",
    value: "+7",
    key: "RU",
  },
  {
    name: "Rwanda",
    label: "+250",
    value: "+250",
    key: "RW",
  },
  {
    name: "Reunion",
    label: "+262",
    value: "+262",
    key: "RE",
  },
  {
    name: "Saint Barthelemy",
    label: "+590",
    value: "+590",
    key: "BL",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    label: "+290",
    value: "+290",
    key: "SH",
  },
  {
    name: "Saint Kitts and Nevis",
    label: "+1869",
    value: "+1869",
    key: "KN",
  },
  {
    name: "Saint Lucia",
    label: "+1758",
    value: "+1758",
    key: "LC",
  },
  {
    name: "Saint Martin",
    label: "+590",
    value: "+590",
    key: "MF",
  },
  {
    name: "Saint Pierre and Miquelon",
    label: "+508",
    value: "+508",
    key: "PM",
  },
  {
    name: "Saint Vincent and the Grenadines",
    label: "+1784",
    value: "+1784",
    key: "VC",
  },
  {
    name: "Samoa",
    label: "+685",
    value: "+685",
    key: "WS",
  },
  {
    name: "San Marino",
    label: "+378",
    value: "+378",
    key: "SM",
  },
  {
    name: "Sao Tome and Principe",
    label: "+239",
    value: "+239",
    key: "ST",
  },
  {
    name: "Saudi Arabia",
    label: "+966",
    value: "+966",
    key: "SA",
  },
  {
    name: "Senegal",
    label: "+221",
    value: "+221",
    key: "SN",
  },
  {
    name: "Serbia",
    label: "+381",
    value: "+381",
    key: "RS",
  },
  {
    name: "Seychelles",
    label: "+248",
    value: "+248",
    key: "SC",
  },
  {
    name: "Sierra Leone",
    label: "+232",
    value: "+232",
    key: "SL",
  },
  {
    name: "Singapore",
    label: "+65",
    value: "+65",
    key: "SG",
  },
  {
    name: "Slovakia",
    label: "+421",
    value: "+421",
    key: "SK",
  },
  {
    name: "Slovenia",
    label: "+386",
    value: "+386",
    key: "SI",
  },
  {
    name: "Solomon Islands",
    label: "+677",
    value: "+677",
    key: "SB",
  },
  {
    name: "Somalia",
    label: "+252",
    value: "+252",
    key: "SO",
  },
  {
    name: "South Africa",
    label: "+27",
    value: "+27",
    key: "ZA",
  },
  {
    name: "South Sudan",
    label: "+211",
    value: "+211",
    key: "SS",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    label: "+500",
    value: "+500",
    key: "GS",
  },
  {
    name: "Spain",
    label: "+34",
    value: "+34",
    key: "ES",
  },
  {
    name: "Sri Lanka",
    label: "+94",
    value: "+94",
    key: "LK",
  },
  {
    name: "Sudan",
    label: "+249",
    value: "+249",
    key: "SD",
  },
  {
    name: "Suriname",
    label: "+597",
    value: "+597",
    key: "SR",
  },
  {
    name: "Svalbard and Jan Mayen",
    label: "+47",
    value: "+47",
    key: "SJ",
  },
  {
    name: "Swaziland",
    label: "+268",
    value: "+268",
    key: "SZ",
  },
  {
    name: "Sweden",
    label: "+46",
    value: "+46",
    key: "SE",
  },
  {
    name: "Switzerland",
    label: "+41",
    value: "+41",
    key: "CH",
  },
  {
    name: "Syrian Arab Republic",
    label: "+963",
    value: "+963",
    key: "SY",
  },
  {
    name: "Taiwan",
    label: "+886",
    value: "+886",
    key: "TW",
  },
  {
    name: "Tajikistan",
    label: "+992",
    value: "+992",
    key: "TJ",
  },
  {
    name: "Tanzania, United Republic of Tanzania",
    label: "+255",
    value: "+255",
    key: "TZ",
  },
  {
    name: "Thailand",
    label: "+66",
    value: "+66",
    key: "TH",
  },
  {
    name: "Timor-Leste",
    label: "+670",
    value: "+670",
    key: "TL",
  },
  {
    name: "Togo",
    label: "+228",
    value: "+228",
    key: "TG",
  },
  {
    name: "Tokelau",
    label: "+690",
    value: "+690",
    key: "TK",
  },
  {
    name: "Tonga",
    label: "+676",
    value: "+676",
    key: "TO",
  },
  {
    name: "Trinidad and Tobago",
    label: "+1868",
    value: "+1868",
    key: "TT",
  },
  {
    name: "Tunisia",
    label: "+216",
    value: "+216",
    key: "TN",
  },
  {
    name: "Turkey",
    label: "+90",
    value: "+90",
    key: "TR",
  },
  {
    name: "Turkmenistan",
    label: "+993",
    value: "+993",
    key: "TM",
  },
  {
    name: "Turks and Caicos Islands",
    label: "+1649",
    value: "+1649",
    key: "TC",
  },
  {
    name: "Tuvalu",
    label: "+688",
    value: "+688",
    key: "TV",
  },
  {
    name: "Uganda",
    label: "+256",
    value: "+256",
    key: "UG",
  },
  {
    name: "Ukraine",
    label: "+380",
    value: "+380",
    key: "UA",
  },
  {
    name: "United Arab Emirates",
    label: "+971",
    value: "+971",
    key: "AE",
  },
  {
    name: "United Kingdom",
    label: "+44",
    value: "+44",
    key: "GB",
  },
  {
    name: "United States",
    label: "+1",
    value: "+1",
    key: "US",
  },
  {
    name: "Uruguay",
    label: "+598",
    value: "+598",
    key: "UY",
  },
  {
    name: "Uzbekistan",
    label: "+998",
    value: "+998",
    key: "UZ",
  },
  {
    name: "Vanuatu",
    label: "+678",
    value: "+678",
    key: "VU",
  },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    label: "+58",
    value: "+58",
    key: "VE",
  },
  {
    name: "Vietnam",
    label: "+84",
    value: "+84",
    key: "VN",
  },
  {
    name: "Virgin Islands, British",
    label: "+1284",
    value: "+1284",
    key: "VG",
  },
  {
    name: "Virgin Islands, U.S.",
    label: "+1340",
    value: "+1340",
    key: "VI",
  },
  {
    name: "Wallis and Futuna",
    label: "+681",
    value: "+681",
    key: "WF",
  },
  {
    name: "Yemen",
    label: "+967",
    value: "+967",
    key: "YE",
  },
  {
    name: "Zambia",
    label: "+260",
    value: "+260",
    key: "ZM",
  },
  {
    name: "Zimbabwe",
    label: "+263",
    value: "+263",
    key: "ZW",
  },
];

/* eslint-disable */
// @ts-ignore
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/25581
export default compose(
  sortBy(prop("value")),
  uniqBy(prop("value")),
)(dialCodes) as SelectOption[];
/* eslint-enable */
